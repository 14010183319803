@import "~react-image-gallery/styles/css/image-gallery.css";


html {
  box-sizing: border-box;
  font-family: Roboto;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.__json-pretty__ {
  line-height: 1.3;
  color: #66d9ef;
  background: #272822;
  overflow-x: auto;
  user-select: text;
}
.__json-pretty__ .__json-key__ {
  color: #f92672;
}
.__json-pretty__ .__json-value__ {
  color: #a6e22e;
}
.__json-pretty__ .j__son-string__ {
  color: #fd971f;
}
.__json-pretty__ .__json-boolean__ {
  color: #ac81f0;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.table-hover tr:hover {
  background: #e0e0e05e;
}


.fade-in {  
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  
  visibility: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.image-gallery {
  max-width: calc(100vw - 50px);
}

.image-gallery-slide .image-gallery-description {
  bottom: 0
}